import React, { useState, useEffect, useRef } from "react";
import { collection, doc, query, orderBy, onSnapshot, getDoc, where, getDocs } from 'firebase/firestore';
import { db,auth, } from "./firebase";
import { ListGroup, Modal, Button, Navbar } from 'react-bootstrap';
import mapboxgl from 'mapbox-gl';


const fetchMapboxToken = async () => {
  try {
    const user = auth.currentUser;
      if (!user) {
          throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();

      const response = await fetch('https://us-central1-sepa-firehouse.cloudfunctions.net/getMapboxToken', {
          headers: {
              'Authorization': `Bearer ${idToken}`
          }
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.token;
  } catch (error) {
      console.error('Error fetching Mapbox token:', error);
  }
};


function UserList({ agencyAccessCode }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const [enableSound, setEnableSound] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [quadrants, setQuadrants] = useState([]);
  const alertSound = new Audio('/dispatch.wav');
  const ttsAlert = new Audio('/notif.mp3');
  const [settings, setSettings] = useState({});
  const [ttsAPI, setTtsAPI] = useState('');
  const mapContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioQueue, setAudioQueue] = useState([]);
  const [units, setUnits] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [unitsCollectionName, setUnitsCollectionName] = useState('');


  const fetchUnits = async (incidentId) => {
    const collectionName = unitsCollectionName || 'null';
    const q = query(collection(db, collectionName), where("cfsId", "==", incidentId));
    const querySnapshot = await getDocs(q);
    const unitsData = querySnapshot.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      .sort(sortUnitsByStatus); // Sort here before updating state
    setUnits(unitsData);
  };
  const getBackgroundColor = (status) => {
    
    switch (status) {
      case 'Off Shift':
        return 'rgba(67, 66, 77, 0.8)'; // DONE
      case 'Available for calls':
        return 'rgba(33, 209, 159, 0.8)'; // DONE
      case 'In Quarters':
        return 'rgba(94, 92, 108, 0.8)'; // DONE
      case 'At Scene':
        return 'rgba(187, 10, 33, 0.8)'; // DONE
      case 'On Scene':
        return 'rgba(187, 10, 33, 0.8)'; // DONE
      case 'Staffed':
        return 'rgba(206, 83, 116, 0.8)'; // DONE
      case 'Unit Fulfilled':
        return 'rgba(3, 96, 22, 0.8)'; // DONE
      case 'Acknowledged':
          return 'rgba(3, 96, 22, 0.8)'; // DONE
      case 'Enroute':
        return 'rgba(5, 142, 63, 0.8)'; // DONE
      case 'Dispatched':
        return 'rgba(113, 114, 30, 0.8)'; // DONE
      case 'Arrived at Patient':
        return 'rgba(186, 39, 74, 0.8)'; // DONE
      case 'Transport':
        return 'rgba(96, 178, 229, 0.8)'; // DONE
      case 'Transporting to Hospital':
          return 'rgba(96, 178, 229, 0.8)'; // DONE
      case 'Transport Complete':
        return 'rgba(14, 31, 68, 0.8)'; // DONE
      case 'At Hospital':
          return 'rgba(14, 31, 68, 0.8)'; // DONE
      case 'Repairs':
        return 'rgba(184, 111, 82, 0.8)'; // DONE
      case 'Administrtive':
        return 'rgba(52, 9, 12, 0.8)'; // DONE
      case 'DECON':
          return 'rgba(52, 9, 12, 0.8)'; // DONE
      case 'REGEN':
        return 'rgba(52, 9, 12, 0.8)'; // DONE
      case 'ALS Full Unit':
        return 'rgba(67, 66, 77, 0.8)'; // DONE
      case 'Crew Tone':
        return 'rgba(156, 82, 139, 0.8)'; // DONE
      default:
        return 'rgba(0, 0, 0, 0.8)'; // Black
    }
    };
    // Define the order for sorting statuses
  const statusOrder = {
    'Enroute': 1,
    'Acknowledged': 1,
    'On Scene': 2,
    'Dispatched': 3,
    'Transporting to Hospital': 4,
    'At Hospital': 4,
    'Default': 100 // For any status not listed
  };

  // Sort function for units based on status
  const sortUnitsByStatus = (a, b) => {
    const orderA = statusOrder[a.Status] || statusOrder['Default'];
    const orderB = statusOrder[b.Status] || statusOrder['Default'];
    return orderA - orderB;
  };
   // Fetch agency settings and notification types
  useEffect(() => {
    const fetchAgencySettings = async () => {
      try {
        const docRef = doc(db, 'agencySettings', agencyAccessCode);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setQuadrants(data.quadrants || []);
          setNotificationTypes(data.notificationTypes || []);
          setTtsAPI(data.ttsAPI || '');
          setUnitsCollectionName(data.unitsCollection);
          // Initialize settings for each notification type
          const initialSettings = data.notificationTypes.reduce((acc, type) => {
            acc[type] = {};
            return acc;
          }, {});
          setSettings(initialSettings);
        } else {
          
        }
      } catch (error) {
        console.error('Error fetching agency settings:', error);
      }
    };

    if (agencyAccessCode) {
      fetchAgencySettings();
    }
  }, [agencyAccessCode]);

  useEffect(() => {
    const q = query(
      collection(db, agencyAccessCode),
      orderBy("createDateTime", "desc")
    );
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const now = new Date();
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const userData = change.doc.data();
          // Handle different types for createDateTime
          let userDateTime;
          if (userData.createDateTime?.toDate) { // Firestore Timestamp
            userDateTime = userData.createDateTime.toDate();
          } else if (userData.createDateTime instanceof Date) { // JavaScript Date
            userDateTime = userData.createDateTime;
          } else if (typeof userData.createDateTime === 'string') { // String
            userDateTime = new Date(userData.createDateTime);
          }
  
          if (userDateTime) {
            const timeDifference = now - userDateTime;
            if (timeDifference <= 60000) { // 1 minute in milliseconds
              const userType = userData.type;
              const userQuadrant = userData.quadrant;
    
              if (notificationTypes.includes(userType)) {
                const selectedQuadrants = settings[userType] || {};
                const matchedQuadrant = Object.keys(selectedQuadrants).find((q) => q.slice(0, 2) === userQuadrant.slice(0, 2) && selectedQuadrants[q]);
    
                if (matchedQuadrant && enableSound) {
                  fetchTtsAndAddToQueue(userData.callType, userData.location);
                }
              }
            }
          }
        }
      });
      setUsers(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  
    return () => unsubscribe();
  }, [agencyAccessCode, settings, notificationTypes, enableSound]);
  
  const fetchTtsAndAddToQueue = async (callType, location) => {
    try {
      const response = await fetch(ttsAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ callType, location }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }
  
      const { audioSource } = await response.json();
      
      
      // Since audioSource is already a data URL, use it directly
      const audioSequence = [alertSound, ttsAlert, new Audio(audioSource), ttsAlert, new Audio(audioSource)];
      
      setAudioQueue(currentQueue => [...currentQueue, audioSequence]);
    } catch (error) {
      console.error('Error fetching TTS:', error);
    }
  };
  

  useEffect(() => {
    // Assuming `audioQueue` contains items to be played sequentially
    if (audioQueue.length > 0 && !isPlaying) {
     
      setIsPlaying(true); // Assume isPlaying is part of your component's state
      const audioSequence = audioQueue[0];
      playAudioSequence(audioSequence).then(() => {
        
        setIsPlaying(false);
        setAudioQueue(currentQueue => currentQueue.slice(1));
      });
    }
  }, [audioQueue, isPlaying]);

  const playAudioSequence = async (sequence) => {
    for (const audio of sequence) {
      await new Promise((resolve) => {
        audio.onended = resolve;
        audio.play().catch((error) => {
          console.error("Error playing audio:", error);
          resolve(); // Resolve promise even if there's an error, to continue the sequence
        });
      });
    }
  };
  
  
  const playAudio = (audioSrc) => {
    return new Promise((resolve) => {
      
      const audio = new Audio(audioSrc);
      audio.onended = resolve;
      audio.play();
    });
  };
  

  const openPopup = (user) => {
    if (user.callType && user.location && user.quadrant) {
      setSelectedUser(user);
      fetchUnits(user.id);
    }
    setIsModalOpen(true);
    
  };

  const closePopup = () => {
    setSelectedUser(null);
    setUnits([]);
    setIsModalOpen(false);
  };

  const handleSoundToggle = () => {
    setEnableSound((prevEnableSound) => !prevEnableSound);
    if (!enableSound) {
      // Play a silent sound or very short sound to satisfy browser autoplay policy
      ttsAlert.play();
    }
  };

  const handleQuadrantToggle = (callType, quadrant) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [callType]: {
        ...prevSettings[callType],
        [quadrant]: !prevSettings[callType]?.[quadrant], // Toggle the value of the quadrant
      },
    }));
  };

  const handleToggleAll = (callType, toggleValue) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [callType]: quadrants.reduce((acc, quadrant) => {
        acc[quadrant] = toggleValue;
        return acc;
      }, {}),
    }));
  };

  useEffect(() => {
    let map;
    fetchMapboxToken().then(token => {
      //mapboxgl.accessToken = token;
      mapboxgl.accessToken = "pk.eyJ1Ijoiam9zZXBoZGFtYnJvc2lvIiwiYSI6ImNsZ2RwcTVkMTBhM2czZmxoYjdiYWZscWcifQ.NV_WL72L54Yd1oHHEdeEfg";
      // Initialize your Mapbox map here
    });
    if (isModalOpen && selectedUser && selectedUser.latitudeY && selectedUser.longitudeX && mapContainerRef.current) {
      const coordinates = [selectedUser.longitudeX, selectedUser.latitudeY];
  
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/josephdambrosio/clgdq3hye009e01mwigchpmp1',
        center: coordinates,
        zoom: 16
      });

      // Create a marker and add it to the map
      new mapboxgl.Marker()
        .setLngLat(coordinates) // Set the marker's coordinates
        .addTo(map); // Add it to the map
      // Delay resizing to ensure the modal is fully rendered
      setTimeout(() => {
        if (map) map.resize();
      }, 300); // Adjust the timeout as needed
      return () => {
        if (map) map.remove();
      };
    }
  }, [isModalOpen, selectedUser]);
  

  // Inline styles for the grid layout
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Adjust as needed
    gap: '10px', // Adjust as needed
  };

  // Inline style for each unit item
const unitItemStyle = status => ({
  backgroundColor: getBackgroundColor(status),
  color: 'white',
  padding: '5px',
  display: 'flex',        // Add this line
  alignItems: 'center',   // Add this line
  justifyContent: 'center', // Add this line
  textAlign: 'center',    // Keep for horizontal text alignment
  marginBottom: '5px',
  height: '100%',         // Optional: Set a specific height if needed
});

  return (
    <div style={{ width: '100%', maxWidth: '100vw' }}>
    <Navbar bg="light">
  <Navbar.Collapse className="justify-content-end">
    <Button variant={enableSound ? "primary" : "secondary"} onClick={handleSoundToggle} className="mr-2">
      {enableSound ? "Disable Sound Alerts" : "Enable Sound Alerts"}
    </Button>
    <Button onClick={() => setSettingsModalShow(true)} className="btn-spacing">Filter Stations</Button>
  </Navbar.Collapse>
</Navbar>



<Modal show={settingsModalShow} onHide={() => setSettingsModalShow(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Filter Stations</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {notificationTypes.map((callType) => (
      <div key={callType}>
        <h5>{callType}</h5>
        <div className="grid">
          <div className="text-center mt-3">
            <Button
              variant="primary"
              onClick={() => handleToggleAll(callType, true)}
              className="mr-2"
            >
              All On
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleToggleAll(callType, false)}
            >
              All Off
            </Button>
          </div>
          {quadrants.map((quadrant) => (
            <Button
              key={quadrant}
              variant={settings[callType]?.[quadrant] ? "primary" : "secondary"}
              onClick={() => handleQuadrantToggle(callType, quadrant)}
            >
              {quadrant}
            </Button>
          ))}
        </div>
      </div>
    ))}
  </Modal.Body>
</Modal>
      <ListGroup>
        {users.map((user) => {
          // Calculate the combined RGB value
          const combinedRGB = `rgb(${user.backgroundR}, ${user.backgroundG}, ${user.backgroundB})`;
          return (
            <ListGroup.Item
            key={user.id}
            className="d-flex justify-content-between align-items-center"
            onClick={() => openPopup(user)}
            style={{
              backgroundColor: 'black',
              color: combinedRGB,
              fontWeight: 'bold'
            }}
          >
            <div>
              <div style={{ fontWeight: 'bold', fontSize: '2rem' }}>{user.callType}</div>
              <div style={{ color: 'white', fontSize: '1rem' }}>{user.location}</div>
            </div>
            <div style={{ color: 'white', fontWeight: 'bold', fontSize: '1rem' }}>
              <div>{user.quadrant}</div>
            </div>
          </ListGroup.Item>
          );
        })}
      </ListGroup>


      <Modal show={selectedUser !== null} 
              onHide={closePopup}
              dialogClassName="modal-lg" 
              >
        <Modal.Header closeButton>
          <Modal.Title>Incident Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  {selectedUser && (
    <div>
      <div ref={mapContainerRef} style={{ height: '400px' }}></div>
      <hr />
      <div>
          <strong>Units:</strong>
          <div style={gridStyle}>
            {units.map(unit => (
              <div key={unit.id} style={unitItemStyle(unit.Status)}>
                {unit.unitNumber} - {unit.Status}
              </div>
            ))}
          </div>
        </div>
        < hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <p><strong>Location:</strong> {selectedUser.location || 'N/A'}</p>
        <p><strong>Dispatch Date/Time:</strong> {selectedUser.dispatchedDateTime || 'N/A'}</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p><strong>Call Type:</strong> {selectedUser.callType || 'N/A'}</p>
        <p><strong>Quadrant:</strong> {selectedUser.quadrant || 'N/A'}</p>
      </div>
      <hr />
      {Array.isArray(selectedUser.narratives) && selectedUser.narratives.length > 0 ? (
        selectedUser.narratives
          .sort((a, b) => b.enteredDate.seconds - a.enteredDate.seconds)
          .map((narrativeObj, index) => (
            <div key={index}>{narrativeObj.narrative}</div>
          ))
      ) : (
        <p>No narratives available.</p>
      )}
    </div>
  )}
</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closePopup}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserList;